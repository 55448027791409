.site-center {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  > .container {
    width: 60rem;

    &.-narrow {
      width: 50rem;
    }

    &.-wide {
      width: 70rem;
    }
  }
}

.section-feature {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  &.-small,
  &.-small > .tint {
    min-height: 300px;
    min-height: 18.75rem;
  }

  &.-large,
  &.-large > .tint {
    min-height: 600px;
    min-height: 37.5rem;
  }

  &.-image1 {
    background-image: url('../images/ss2-table-spread.jpg');
  }

  > .tint {
    width: 100%;
  }

  > .tint.-brownfade {
    background-image: linear-gradient(170deg, rgba(136, 130, 120, 0.98) 0%, rgba(171, 156, 135, 0.6) 100%);
    color: $text-inverse;
  }

  > .tint.-bluefade {
    background-image: linear-gradient(170deg, rgba(125, 136, 165, 0.98) 0%, rgba(253, 253, 253, 0.6) 100%);
    color: $text-inverse;
  }
}

.hero-feature {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 28rem;
  justify-content: center;
  text-align: center;

  > .pitch {
    font-size: 3rem;
    // font-weight: lighter;
    font-weight: 300;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  }

  > .action {
    background-color: $color-brand;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    color: $text-inverse;
    display: block;
    font-size: 1.5rem;
    margin-top: 2rem;
    padding: 0.8em 2em;
    text-decoration: none;
  }

  > .action:active {
    box-shadow: 0 0 0;
  }
}

.section-zone {
  margin: 0;
  padding: 1em;

  @media (min-width: 60rem) {
    text-align: justify;
  }

  > p.dropcap:first-child::first-letter {
    float: left;
    font-size: 3.6em;
    font-weight: lighter;
    line-height: 1em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    // padding-top: 0.2em;
  }

  &.-textlarge {
    font-size: 1.6rem;
  }

  &.-paddinglarge {
    @media (min-width: 60rem) {
      padding: 2em 6em;
    }
  }
}
