._text-center {
  text-align: center !important;
}

._text-justify {
  text-align: justify !important;
}

._responsive-image {
  max-width: 100%;
}

._margin-zero {
  margin: 0 !important;
}

._text-brand {
  color: $color-brand;
}

._bold {
  font-weight: bold;
}
