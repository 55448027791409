@import url(normalize.css);
@import url(../../node_modules/flexboxgrid/dist/flexboxgrid.css);
* {
  box-sizing: border-box;
}

body {
  color: #333;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.site-center {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.site-center > .container {
  width: 60rem;
}

.site-center > .container.-narrow {
  width: 50rem;
}

.site-center > .container.-wide {
  width: 70rem;
}

.section-feature {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.section-feature.-small,
.section-feature.-small > .tint {
  min-height: 300px;
  min-height: 18.75rem;
}

.section-feature.-large,
.section-feature.-large > .tint {
  min-height: 600px;
  min-height: 37.5rem;
}

.section-feature.-image1 {
  background-image: url("../images/ss2-table-spread.jpg");
}

.section-feature > .tint {
  width: 100%;
}

.section-feature > .tint.-brownfade {
  background-image: linear-gradient(170deg, rgba(136, 130, 120, 0.98) 0%, rgba(171, 156, 135, 0.6) 100%);
  color: white;
}

.section-feature > .tint.-bluefade {
  background-image: linear-gradient(170deg, rgba(125, 136, 165, 0.98) 0%, rgba(253, 253, 253, 0.6) 100%);
  color: white;
}

.hero-feature {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 28rem;
  justify-content: center;
  text-align: center;
}

.hero-feature > .pitch {
  font-size: 3rem;
  font-weight: 300;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
}

.hero-feature > .action {
  background-color: #1c3f94;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: white;
  display: block;
  font-size: 1.5rem;
  margin-top: 2rem;
  padding: 0.8em 2em;
  text-decoration: none;
}

.hero-feature > .action:active {
  box-shadow: 0 0 0;
}

.section-zone {
  margin: 0;
  padding: 1em;
}

@media (min-width: 60rem) {
  .section-zone {
    text-align: justify;
  }
}

.section-zone > p.dropcap:first-child::first-letter {
  float: left;
  font-size: 3.6em;
  font-weight: lighter;
  line-height: 1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.section-zone.-textlarge {
  font-size: 1.6rem;
}

@media (min-width: 60rem) {
  .section-zone.-paddinglarge {
    padding: 2em 6em;
  }
}

.nav-bar {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  padding: 1em;
}

@media (min-width: 60rem) {
  .nav-bar > .button {
    display: none;
  }
  .nav-bar > .items > * {
    display: inline-block;
    margin: 0 0.4em;
  }
}

@media (max-width: 60rem) {
  .nav-bar {
    flex-wrap: wrap;
  }
  .nav-bar > .items {
    flex-basis: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
  }
  .nav-bar > .items > * {
    display: block;
    padding: 0.8em 0.4em;
  }
  .nav-bar > .items > *:first-of-type {
    padding-top: 1.5em;
  }
  .nav-bar > .items > *:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .nav-bar > .items > *:not(:nth-child(even)):last-child {
    border-bottom: rgba(0, 0, 0, 0.4) 2px dotted;
  }
  .nav-bar > .toggle:checked ~ .items {
    height: auto;
    height: 60vh;
    overflow: auto;
  }
}

.nav-bar > .toggle {
  display: none;
}

.nav-bar > .button {
  cursor: pointer;
  font-size: 1.4em;
  margin-right: 0.8em;
  padding: 0 0.3em;
}

.nav-bar > .brand {
  color: #1c3f94;
  cursor: default;
  font-family: "Cinzel", serif;
  font-size: 2.25rem;
  font-weight: bold;
  padding-top: 0.2em;
  user-select: none;
}

.nav-bar > .brand > picture > img {
  vertical-align: sub;
}

.nav-bar > .items {
  font-weight: lighter;
  margin-left: auto;
}

.nav-bar > .items > * {
  color: white;
  text-decoration: none;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.section-footer {
  background-color: #1c3f94;
  color: white;
  font-size: 1.2em;
  font-weight: lighter;
  padding: 2em 1em;
  text-align: center;
}

._text-center {
  text-align: center !important;
}

._text-justify {
  text-align: justify !important;
}

._responsive-image {
  max-width: 100%;
}

._margin-zero {
  margin: 0 !important;
}

._text-brand {
  color: #1c3f94;
}

._bold {
  font-weight: bold;
}
