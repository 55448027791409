.nav-bar {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  padding: 1em;

  @media (min-width: 60rem) {
    > .button {
      display: none;
    }

    > .items > * {
      display: inline-block;
      margin: 0 0.4em;
    }
  }

  @media (max-width: 60rem) {
    flex-wrap: wrap;
    // font-size: 1.15rem;

    > .brand {
      // font-size: 2rem;
    }

    > .items {
      flex-basis: 100%;
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
    }

    > .items > * {
      display: block;
      padding: 0.8em 0.4em;
    }

    > .items > *:first-of-type {
      padding-top: 1.5em;
    }

    > .items > *:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.2);
    }

    > .items > *:not(:nth-child(even)):last-child {
      border-bottom: rgba(0, 0, 0, 0.4) 2px dotted;
    }

    > .toggle:checked ~ .items {
      height: auto;
      height: 60vh;
      overflow: auto;
    }
  }

  > .toggle {
    display: none;
  }

  > .button {
    cursor: pointer;
    font-size: 1.4em;
    margin-right: 0.8em;
    padding: 0 0.3em;
  }

  > .brand {
    color: $color-brand;
    cursor: default;
    font-family: $font-brand;
    font-size: 2.25rem;
    font-weight: bold;
    padding-top: 0.2em;
    user-select: none;
  }

  > .brand > picture > img {
    vertical-align: sub;
  }

  > .items {
    font-weight: lighter;
    margin-left: auto;
  }

  > .items > * {
    color: $text-inverse;
    text-decoration: none;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }
}
