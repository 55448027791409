.section-footer {
  background-color: $color-brand;
  // color: lighten($text-default, 40);
  color: $text-inverse;
  font-size: 1.2em;
  font-weight: lighter;
  // padding: 1em 1em 2em;
  padding: 2em 1em;
  text-align: center;
}
