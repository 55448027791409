body {
  color: $text-default;
  font-family: $font-system;
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

// p {
//   @media (max-width: 60em) {
//     font-size: 1.1em;
//   }
// }
